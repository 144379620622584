export const fetchResults = async ({ raceSplitPath, splitNames }) => {
  const raceBasePath = raceSplitPath
    .split('/')
    .slice(0, -1)
    .join('/');
  const fetchResult = await fetch('/.netlify/functions/resultFetcher', {
    method: 'post',
    body: JSON.stringify({ raceBasePath, splitNames }),
  });
  return await fetchResult.json();
};

export const parseResultDataChanges = (previousResult, newResult) => {
  const newSplits = Object.keys(newResult);

  const oldBibsBySplits = Object.keys(previousResult).reduce((prev, key) => {
    if (newSplits.includes(key)) {
      prev[key] = previousResult[key].map(result => result.bib);
    }
    return prev;
  }, {});

  const newResultsWithHighLight = newSplits.reduce((prev, key) => {
    const oldBibs = oldBibsBySplits[key];
    if (typeof oldBibs !== 'undefined' && oldBibs.length > 0) {
      prev[key] = newResult[key].map(result =>
        oldBibs.includes(result.bib) ? result : { ...result, highlight: true }
      );
    } else {
      prev[key] = newResult[key];
    }
    return prev;
  }, {});

  return newResultsWithHighLight;
};
