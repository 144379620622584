import React from 'react';
import { DataGridRow, DataGridItem } from '../common';

export const StartListRow = ({
  name,
  team,
  time,
  bib,
  place,
  timeDiff,
  highlight = false,
}) => {
  return (
    <DataGridRow id={bib} variant={highlight ? 'highlight' : ''}>
      <DataGridItem variant="place">{place}</DataGridItem>
      <DataGridItem variant="bib">{bib}</DataGridItem>
      <DataGridItem variant="name">
        <div className="name">{name}</div>
        <div className="club club--gray">{team}</div>
      </DataGridItem>
      <DataGridItem variant="time">{time}</DataGridItem>
      <DataGridItem variant="time">{timeDiff}</DataGridItem>
    </DataGridRow>
  );
};
