import React from 'react';
import PropTypes from 'prop-types';

import { Button } from '..';

export const ButtonLongPress = ({ pressDelay, onLongPress, ...props }) => {
  let longPress;

  const handleButtonPress = () => {
    longPress = setTimeout(onLongPress, pressDelay);
  };

  const handleButtonRelease = () => clearTimeout(longPress);

  return (
    <Button
      onTouchStart={handleButtonPress}
      onTouchEnd={handleButtonRelease}
      onMouseDown={handleButtonPress}
      onMouseUp={handleButtonRelease}
      onMouseLeave={handleButtonRelease}
      {...props}
    />
  );
};

ButtonLongPress.defaultProps = {
  pressDelay: 1500,
};

ButtonLongPress.propTypes = {
  pressDelay: PropTypes.number,
  onLongPress: PropTypes.func.isRequired,
};
