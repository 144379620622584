import React from 'react';

import './input.scss';

export const Input = ({ className = '', children, ...props }) => (
  <label className="input__container">
    {children && <p className="input__label">{children}</p>}
    <input className={`input ${className}`} {...props} />
  </label>
);
