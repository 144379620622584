import React from 'react';
import PropTypes from 'prop-types';

import './page-title.scss';

export const PageTitle = ({ level, className, children, ...props }) => {
  const Level = level;
  return (
    <Level
      className={`page-title page-title--${Level} ${className}`}
      {...props}>
      {children}
    </Level>
  );
};

PageTitle.propTypes = {
  level: PropTypes.oneOf(['h1', 'h2', 'h3', 'h4']),
  className: PropTypes.string,
};

PageTitle.defaultProps = {
  level: 'h1',
  className: '',
};
