import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';

import { PageTitle } from '../PageTitle/PageTitle';

import './raceName.scss';

const RACE_INFO_ENDPOINT = process.env.REACT_APP_RACE_INFO;
const RACE_INFO_ENDPOINT_TIMING = process.env.REACT_APP_RACE_INFO_TIMING;

export const RaceName = ({ raceId }) => {
  const [raceName, setRaceName] = useState('');

  useEffect(() => {
    const fetchRaceName = async () => {
      const correntEndpoint =
        raceId.length < 30 ? RACE_INFO_ENDPOINT : RACE_INFO_ENDPOINT_TIMING;
      const response = await fetch(correntEndpoint + raceId);
      const raceInfo = await response.json();
      setRaceName(raceInfo.name);
    };
    if (typeof raceId !== 'undefined') {
      fetchRaceName();
    }
  }, [raceId]);

  return (
    <PageTitle level="h2" className="race-name">
      {raceName}
    </PageTitle>
  );
};

RaceName.propTypes = {
  raceId: PropTypes.number.isRequired,
};
