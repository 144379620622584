import React from 'react';
import PropTypes from 'prop-types';

export const CategoryNavigationItem = ({ name }) => (
  <li className="category-navigation__item">
    <a className="category-navigation__link" href={`#${name}`}>
      {name}
    </a>
  </li>
);

CategoryNavigationItem.propTypes = {
  name: PropTypes.string.isRequired,
};
