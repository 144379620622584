import React from 'react';
import PropTypes from 'prop-types';

import { ResultsBody } from './results.body';
import { PageTitle, ShadowContainer, DataGridContainer } from '../common';
import { ResultsHeader } from './results.header';

import './results-category.scss';

export const ResultsCategory = ({ results, children, category }) => {
  return typeof results !== 'undefined' && results.length > 0 ? (
    <div>
      <PageTitle level="h3" className="results-category__title" id={category}>
        {category}
      </PageTitle>
      {children}
      <ShadowContainer>
        <DataGridContainer>
          <ResultsHeader />
          <ResultsBody results={results} />
        </DataGridContainer>
      </ShadowContainer>
    </div>
  ) : null;
};

ResultsCategory.propTypes = { results: PropTypes.array.isRequired };
