import React from 'react';
import PropTypes from 'prop-types';

import './dataGrid.row.scss';

export const DataGridRow = ({ children, variant, size }) => {
  const classes = ['data-grid__row'];
  if (variant) classes.push(`data-grid__row--${variant}`);
  if (size) classes.push(`data-grid__row--${size}`);

  return <tr className={classes.join(' ')}>{children}</tr>;
};

DataGridRow.propTypes = {
  variant: PropTypes.oneOf(['', 'highlight', 'passive']),
  size: PropTypes.oneOf(['', 'narrow']),
};
