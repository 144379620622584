import React, { useEffect, useState } from 'react';
import {
  PageTitle,
  Container,
  LoaderCenter,
  CategoryNavigation,
  RaceClock,
} from '../common';

import { ResultsCategory } from './results.category';
import { Element } from '../common/Element/element';

const Results = ({ results, splitKeys }) => {
  const [categoryNavData, setCategoryNavData] = useState([]);

  useEffect(() => {
    const categoryNavData = splitKeys.map(splitKey => ({ key: splitKey }));
    setCategoryNavData(categoryNavData);
  }, [splitKeys]);

  return (
    <Container>
      <Element flex spaceBetween verticalCenter>
        <PageTitle level="h1">Tulokset</PageTitle>
        <RaceClock />
      </Element>
      {splitKeys.length > 0 && (
        <CategoryNavigation categoryList={categoryNavData} />
      )}
      {splitKeys.length === 0 ? (
        <LoaderCenter />
      ) : (
        splitKeys.map(splitKey => (
          <ResultsCategory
            key={splitKey}
            results={results[splitKey]}
            category={splitKey}
          />
        ))
      )}
    </Container>
  );
};

export default Results;
