import React from 'react';

import { DataGridBody } from '../common';
import { StartListRow } from './results.row';

export const ResultsBody = ({ results }) => {
  const leaderTime = results[0].time;

  return (
    <DataGridBody>
      {results.map((competitor, index) => (
        <StartListRow
          key={competitor.bib}
          {...competitor}
          leaderTime={leaderTime}
        />
      ))}
    </DataGridBody>
  );
};
