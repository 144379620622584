import React from 'react';

import { DataGridHeader, DataGridRow, DataGridHeaderItem } from '../common';

export const ResultsHeader = () => (
  <DataGridHeader>
    <DataGridRow>
      <DataGridHeaderItem variant="place">Sija</DataGridHeaderItem>
      <DataGridHeaderItem variant="bib">Nro</DataGridHeaderItem>
      <DataGridHeaderItem variant="name">
        <div className="name">Nimi</div>
        <div className="club">Seura</div>
      </DataGridHeaderItem>
      <DataGridHeaderItem variant="time">Aika</DataGridHeaderItem>
      <DataGridHeaderItem variant="time">Ero</DataGridHeaderItem>
    </DataGridRow>
  </DataGridHeader>
);
