import React, { useState, useEffect, useCallback } from 'react';
import { Settings } from './settings';
import Results from './components/Results/Results';
import { Container, Input, Button } from './components/common';
import { fetchResults, parseResultDataChanges } from './result.service';

function App() {
  const [raceSplitPath, setRaceSplitPath] = useState('');
  const [splitNames, setSplitNames] = useState({});
  const [resultData, setResultData] = useState({});
  const [filteredResultData, setFilteredResultData] = useState({});
  const [splitKeys, setSplitKeys] = useState([]);
  const [filter, setFilter] = useState('');
  const [isFiltered, setIsFiltered] = useState(true);

  const fetchAndUpdateResultData = useCallback(
    async ({ raceSplitPath, splitNames }) => {
      const newResultData = await fetchResults({ raceSplitPath, splitNames });
      setResultData(previousResult => {
        setResultData(parseResultDataChanges(previousResult, newResultData));
      });
    },
    [setResultData]
  );

  useEffect(() => {
    const path = window.location.pathname;
    path.length > 1 && setRaceSplitPath(path);
  }, []);

  useEffect(() => {
    if (Object.entries(splitNames).length > 0 && raceSplitPath.length > 0) {
      fetchAndUpdateResultData({ raceSplitPath, splitNames });
    }
  }, [raceSplitPath, splitNames, fetchAndUpdateResultData]);

  useEffect(() => {
    const sortedSplitNameKeys = Object.entries(splitNames)
      .sort(
        (a, b) =>
          parseInt(a[1].split('_')[2], 10) > parseInt(b[1].split('_')[2], 10)
      )
      .map(split => split[0]);
    setSplitKeys(sortedSplitNameKeys);
  }, [splitNames]);

  useEffect(() => {
    const filterData = Object.keys(resultData).reduce((prev, resultDataKey) => {
      prev[resultDataKey] = resultData[resultDataKey].filter(
        result =>
          result.team.toLowerCase().includes(filter.toLowerCase()) ||
          result.name.toLowerCase().includes(filter.toLowerCase())
      );

      return prev;
    }, {});
    setFilteredResultData(filterData);
  }, [filter, resultData]);

  const handleFilterChange = e => setFilter(e.target.value);
  const handleToggleFilter = e => setIsFiltered(e.target.checked);

  const getResultData = () => (isFiltered ? filteredResultData : resultData);

  const handleUpdateResults = () =>
    fetchAndUpdateResultData({ raceSplitPath, splitNames });

  return (
    <div className="App">
      <Container flex flexRow spaceBetween>
        <Settings
          raceName={raceSplitPath}
          splitNames={splitNames}
          setRaceName={setRaceSplitPath}
          setSplitNames={setSplitNames}
        />
        <Input
          type="text"
          onChange={handleFilterChange}
          value={filter}
          placeholder="Suodata..."
        />
        <Input
          type="checkbox"
          checked={isFiltered}
          onChange={handleToggleFilter}>
          Suodata
        </Input>
        <Button onClick={handleUpdateResults}>Päivitä</Button>
      </Container>

      <Results results={getResultData()} splitKeys={splitKeys} />
    </div>
  );
}

export default App;
