import React from 'react';

import './shadow.scss';

export const ShadowContainer = ({ children, ...props }) => {
  const classes = ['shadow'];
  if ('className' in children.props) classes.push(children.props.className);

  const child = React.cloneElement(children, {
    ...props,
    className: classes.join(' '),
  });
  return child;
};
