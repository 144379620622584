import React from 'react';
import PropTypes from 'prop-types';

import './dataGrid.item.scss';

export const DataGridItem = ({ TagName = 'td', variant, size, visibility, children }) => {
  const classes = ['data-grid__item'];
  if (variant === 'bib') classes.push('data-grid__item--bib');
  else if (variant === 'place') classes.push('data-grid__item--place');
  else if (variant === 'category') classes.push('data-grid__item--category');
  else if (variant === 'name') classes.push('data-grid__item--name');
  else if (variant === 'time') classes.push('data-grid__item--time');
  else if (variant === 'status') classes.push('data-grid__item--status');
  else if (variant === 'edit') classes.push('data-grid__item--edit');
  else if (variant === 'differential') classes.push('data-grid__item--differential');
  else if (variant === 'dns') classes.push('data-grid__item--dns');

  if (size) classes.push(`data-grid__item--${size}`);

  if (visibility === "medium-hidden") classes.push('data-grid__item--hidden-medium')

  return <TagName className={classes.join(' ')}>{children}</TagName>;
};

export const DataGridHeaderItem = ({ children, ...props }) => (
  <DataGridItem TagName="th" {...props}>
    {children}
  </DataGridItem>
);

DataGridItem.propTypes = {
  variant: PropTypes.oneOf([
    '',
    'bib',
    'place',
    'category',
    'name',
    'time',
    'status',
    'edit',
    'differential',
    'dns',
  ]),
  size: PropTypes.oneOf(['', 'L', 'XL']),
  visibility: PropTypes.oneOf(['', 'medium-hidden']),
};
