import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';

import './button.scss';

export const Button = ({
  url,
  className,
  color,
  spacing,
  children,
  size,
  textSize,
  width,
  ...props
}) => {
  let TypeOfButton = 'button';
  const linkProperty = {};
  const classes = [className, 'button'];
  if (color === 'blue') classes.push('button--blue');
  else if (color === 'green') classes.push('button--green');
  else if (color === 'red') classes.push('button--red');

  if (spacing === 'large') classes.push('button--l-spacing');
  else if (spacing === 'after') classes.push('button--space-after');

  if (size === 'L') classes.push('button--large');
  if (size === 'S') classes.push('button--small');

  if (width === 'half') classes.push('button--half');

  if (textSize === 'L') classes.push('button--text-large');

  // @TODO: refactor this `if` statement monster
  if (typeof url === 'string' && url.length > 0) {
    if (
      url.substr(0, 8) === 'https://' ||
      url.substr(0, 7) === 'http://' ||
      url.substr(0, 2) === '//' ||
      url.substr(0, 5) === 'blob:'
    ) {
      TypeOfButton = 'a';
      linkProperty['href'] = url;
    } else {
      TypeOfButton = Link;
      linkProperty['to'] = url;
    }
  }

  return (
    <TypeOfButton {...linkProperty} className={classes.join(' ')} {...props}>
      {children}
    </TypeOfButton>
  );
};

Button.propTypes = {
  className: PropTypes.string,
  url: PropTypes.string,
  size: PropTypes.oneOf(['', 'L', 'S']),
  textSize: PropTypes.oneOf(['', 'L']),
  width: PropTypes.oneOf(['', 'half']),
  color: PropTypes.oneOf(['', 'blue', 'green', 'red']),
  spacing: PropTypes.oneOf(['', 'large', 'after']),
};

Button.defaultProps = {
  className: '',
  url: '',
  size: '',
  textSize: '',
  color: 'blue',
  width: '',
  spacing: '',
};
