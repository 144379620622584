import React from 'react';
import PropTypes from 'prop-types';

import './dataGrid.container.scss';

export const DataGridContainer = ({ children, variant, className = '' }) => {
  const classes = ['data-grid__container', className];
  if (variant) classes.push(`data-grid--${variant}`);

  return (
    <div className="data-grid__wrapper">
      <table className={classes.join(' ')}>{children}</table>
    </div>
  );
};

DataGridContainer.propTypes = {
  variant: PropTypes.oneOf(['narrow-data']),
};
