import React, { useEffect } from 'react';
import PropTypes from 'prop-types';

import { CategoryNavigationItem } from '.';

import './category-navigation.scss';

export const CategoryNavigation = ({ categoryList }) => {
  useEffect(() => {
    document.documentElement.classList.add('category-navigation__html-tag');
    return () => {
      document.documentElement.classList.remove(
        'category-navigation__html-tag'
      );
    };
  }, []);

  return (
    <ul className="category-navigation">
      {categoryList.map(category => (
        <CategoryNavigationItem key={category.key} name={category.key} />
      ))}
    </ul>
  );
};

CategoryNavigation.propTypes = {
  categoryList: PropTypes.arrayOf(
    PropTypes.shape({
      key: PropTypes.string.isRequired,
      name: PropTypes.string.isRequired,
    })
  ).isRequired,
};
