import React from 'react';
import PropTypes from 'prop-types';
import { render, unmountComponentAtNode } from 'react-dom';

import { Modal } from '.';

import { Button } from '..';
import { Element } from '../Element/element';

export const ModalConfirm = props => {
  const targetDiv = document.createElement('div');
  targetDiv.id = 'modal-confirm';
  document.body.appendChild(targetDiv);
  render(<ReactModalConfirm {...props} />, targetDiv);
};

const removeElement = () => {
  const targetDiv = document.getElementById('modal-confirm');
  unmountComponentAtNode(targetDiv);
  targetDiv.parentNode.removeChild(targetDiv);
};

const close = () => {
  document.body.classList.remove('modal__no-overflow');
  removeElement();
};

const onButtonClick = cb => {
  cb();
  close();
};

const ReactModalConfirm = ({
  buttonAllow,
  buttonDeny,
  onAllow,
  onDeny,
  modalHeader,
  message,
}) => (
  <Modal isOpen closeModal={close} relative small>
    <Element className="modal__header">
      {modalHeader}
      <Button color="red" className="modal__close" onClick={close}>
        <i className="fas fa-times" />
      </Button>
    </Element>
    <Element className="modal__content" flexColumn>
      {message && <p>{message}</p>}
      <Element flex spaceBetween className="modal__footer-container">
        <Button color="red" onClick={() => onButtonClick(onDeny)}>
          {buttonDeny}
        </Button>
        <Button color="green" onClick={() => onButtonClick(onAllow)}>
          {buttonAllow}
        </Button>
      </Element>
    </Element>
  </Modal>
);

ReactModalConfirm.defaultProps = {
  onDeny: () => null,
  buttonAllow: 'Jatka',
  buttonDeny: 'Peruuta',
  modalHeader: 'Vahvistus',
  message: '',
};

ReactModalConfirm.propTypes = {
  buttonAllow: PropTypes.string,
  buttonDeny: PropTypes.string,
  onAllow: PropTypes.func.isRequired,
  onDeny: PropTypes.func,
  modalHeader: PropTypes.string,
  message: PropTypes.string,
};
