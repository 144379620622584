import React, { useState } from 'react';
import PropTypes from 'prop-types';

import { Modal } from '.';

import { Button, Form } from '..';
import { Element } from '../Element/element';

export const ModalForm = ({ buttonStr, modalHeader, children, onSubmit }) => {
  const [isOpen, setIsOpen] = useState(false);

  const toggleModal = () => setIsOpen(!isOpen);
  const closeModal = () => setIsOpen(false);

  const handleSubmit = e => {
    onSubmit(e) && closeModal();
  };

  return (
    <Element>
      <Button color="green" onClick={toggleModal}>
        {buttonStr}
      </Button>
      <Modal isOpen={isOpen} closeModal={closeModal} relative>
        <Element className="modal__header">
          {modalHeader}
          <Button color="red" className="modal__close" onClick={closeModal}>
            <i className="fas fa-times" />
          </Button>
        </Element>
        <Form className="modal__content" flexColumn onSubmit={handleSubmit}>
          {children}
          <Element className="modal__form-submit" flex spaceBetween>
            <Button type="reset" color="red" onClick={closeModal} width="half">
              peruuta
            </Button>
            <Button type="submit" color="green" width="half">
              Tallenna
            </Button>
          </Element>
        </Form>
      </Modal>
    </Element>
  );
};

ModalForm.propTypes = {
  buttonStr: PropTypes.string.isRequired,
  modalHeader: PropTypes.string.isRequired,
  children: PropTypes.oneOfType([
    PropTypes.element,
    PropTypes.arrayOf(PropTypes.element),
  ]).isRequired,
  onSubmit: PropTypes.func.isRequired,
};
