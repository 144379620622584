import React, { useState, useEffect, useCallback } from 'react';

import { ModalForm, Input, Button } from './components/common';
import { Element } from './components/common/Element/element';

export const Settings = ({
  setRaceName,
  setSplitNames,
  raceName,
  splitNames,
}) => {
  const [raceSplits, setRaceSplits] = useState({});
  const [localRaceName, setLocalRaceName] = useState('');
  const [selectedSplits, setSelectedSplits] = useState(splitNames);
  const onSubmit = e => {
    e.preventDefault();

    setRaceName(localRaceName);
    setSplitNames(selectedSplits);
    return true;
  };

  const fetchSplits = useCallback(
    async raceSplitPath => {
      const response = await fetch('/.netlify/functions/raceSplitFetcher', {
        method: 'post',
        body: JSON.stringify({ raceSplitPath }),
      });
      setRaceSplits(await response.json());
    },
    [setRaceSplits]
  );

  useEffect(() => {
    setLocalRaceName(raceName);
    if (raceName.length > 0) {
      fetchSplits(raceName);
    }
  }, [raceName, setLocalRaceName, fetchSplits]);

  const handleFetchSplitsClick = e => {
    e.preventDefault();
    fetchSplits(localRaceName);
  };

  const handleSplitClick = ({ key, value }) => {
    const updatedSelectedSplits = { ...selectedSplits };
    const splitData = {};
    splitData[key] = value;

    if (key in updatedSelectedSplits) {
      delete updatedSelectedSplits[key];
    } else {
      updatedSelectedSplits[key] = value;
    }
    setSelectedSplits(updatedSelectedSplits);
  };

  return (
    <>
      <ModalForm
        buttonStr="Avaa Asetukset"
        onSubmit={onSubmit}
        modalHeader="Asetukset">
        <Element flex spaceBetween flexRow>
          <Input
            type="text"
            defaultValue={raceName}
            onBlur={e => setLocalRaceName(e.target.value)}>
            Kilpailun nimi
          </Input>
          <Button role="button" onClick={handleFetchSplitsClick}>
            Hae väliajat
          </Button>
        </Element>
        <Element flex spaceBetween flexRow>
          {Object.entries(raceSplits).map(([key, value]) => (
            <Input
              key={key}
              type="checkbox"
              value={value}
              checked={Object.keys(selectedSplits).some(
                selectedSplit => selectedSplit === key
              )}
              onChange={() => handleSplitClick({ key, value })}>
              {key}
            </Input>
          ))}
        </Element>
      </ModalForm>
    </>
  );
};
