import React from 'react';

import { Loader } from './loader';

import './loader.center.scss';

export const LoaderCenter = () => (
  <div className="loader-center">
    <Loader />
  </div>
);
