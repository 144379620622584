export const getRaceTime = () => {
  const timeZoneOffsetMs = new Date().getTimezoneOffset() * -1 * 60 * 1000
  return Date.now() + timeZoneOffsetMs;
};

export const msToStr = (totalMs, format = 'H:MM:SS.f') => {
  // 1- Convert to seconds:
  const ms = totalMs % 1000;
  let seconds = parseInt(totalMs / 1000, 10);
  // 2- Extract hours:
  let hours = parseInt(seconds / 3600, 10); // 3,600 seconds in 1 hour
  hours %= 24;
  seconds %= 3600; // seconds remaining after extracting hours
  // 3- Extract minutes:
  const minutes = parseInt(seconds / 60, 10); // 60 seconds in 1 minute
  // 4- Keep only seconds not extracted to minutes:
  seconds %= 60;
  const addLeadingZero = number => `0${number}`.substr(-2);

  let str = format;
  str = str.replace('HH', addLeadingZero(hours));
  str = str.replace('H', hours);

  str = str.replace('MM', addLeadingZero(minutes));
  str = str.replace('M', minutes);

  str = str.replace('SS', addLeadingZero(seconds));
  str = str.replace('S', seconds);

  str = str.replace('fff', ms);
  str = str.replace('ff', Math.round(ms / 10));
  str = str.replace('f', Math.round(ms / 100));

  return str;
};
