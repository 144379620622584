import React, { useState, useEffect } from 'react';
import { msToStr } from '../../../services/time';

export const Clock = ({ format = 'H.MM.SS', interval = 1000, timeFn = Date.now() }) => {
    const [date, setDate] = useState(timeFn);

    useEffect(() => {
        setTimeout(tick, interval)
    });

    const tick = () => setDate(timeFn);

    return (
        <div>{msToStr(date, format)}</div>
    )
}