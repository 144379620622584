import React, { useEffect } from 'react';
import PropTypes from 'prop-types';

import { Container } from '..';
import { Element } from '../Element/element';

import './modal.scss';

export const Modal = ({
  isOpen,
  children,
  className,
  closeModal,
  small,
  ...props
}) => {
  const handleBgClick = e => {
    if (e.target.classList.contains('modal')) closeModal();
  };

  useEffect(
    () =>
      function cleanup() {
        document.body.removeEventListener('click', handleBgClick);
      }
  );

  const classes = [className, 'modal'];
  const containerClasses = ['modal__container', 'utils--shadow'];
  if (small) containerClasses.push('modal--small');

  if (isOpen) {
    document.body.addEventListener('click', handleBgClick);
    document.body.classList.add('modal__no-overflow');
    classes.push('is-open');
  } else {
    document.body.classList.remove('modal__no-overflow');
    document.body.removeEventListener('click', handleBgClick);
  }

  return (
    isOpen && (
      <Element
        horizontalCenter
        verticalCenter
        flexColumn
        className={classes.join(' ')}>
        <Container inset className={containerClasses.join(' ')} {...props}>
          {children}
        </Container>
      </Element>
    )
  );
};

Modal.defaultProps = {
  className: '',
};

Modal.propTypes = {
  className: PropTypes.string,
  isOpen: PropTypes.bool.isRequired,
  closeModal: PropTypes.func.isRequired,
};
